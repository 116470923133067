import { cn } from "@/lib/utils";
import { Link as InertiaLink, InertiaLinkProps } from "@moraki/inertia-react";
import { forwardRef } from "react";
import { ButtonProps, buttonVariants } from "./button";

interface LinkProps extends Omit<InertiaLinkProps, "size"> {
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ variant = "link", size, ...rest }, ref) => {
    if (rest.target === "_blank") {
      return (
        <a
          ref={ref}
          href={rest.href}
          className={cn(
            buttonVariants({
              variant,
              size: variant == "link" ? "link" : size || "default",
            }),
            rest.className,
          )}
          target={rest.target}
        >
          {rest.children}
        </a>
      );
    }

    return (
      <InertiaLink
        {...rest}
        ref={ref}
        className={cn(
          buttonVariants({
            variant,
            size: variant == "link" ? "link" : size || "default",
          }),
          rest.className,
        )}
      />
    );
  },
);

Link.displayName = "Link";

export default Link;
