// JsFromRoutes CacheKey b3d709f94626cabfa8549f3d54772588
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/autopilot/:autopilot_id/runs'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/autopilot/:autopilot_id/runs'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/autopilot/:autopilot_id/runs/:id'),
}
