// JsFromRoutes CacheKey aa3833174a1586bd235197b86ce6abe6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:type/new'),
  newCustomTask: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/custom/new/:custom_task_id'),
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies/:company_id/assistant/tasks'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:company_id/assistant/tasks/:id'),
}
