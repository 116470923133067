// JsFromRoutes CacheKey c7e954da66297e72174b40855a79cc16
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/lib/api-client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/w/:workspace_slug/companies'),
  new: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/w/:workspace_slug/companies/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/w/:workspace_slug/companies/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/w/:workspace_slug/companies/:id'),
}
