import {
  members,
  otpApp,
  password,
  profile,
  security,
  workspaces,
} from "@/api";
import AvatarWithFallback from "@/components/ui/avatar-with-fallback";
import Icon from "@/components/ui/icon";
import Link from "@/components/ui/link";
import useAuth from "@/lib/hooks/use-auth";
import useCurrentWorkspace from "@/lib/hooks/use-current-workspace";
import { cn } from "@/lib/utils";
import { t } from "i18next";
import { ReactElement } from "react";
import { attrWithFallback, defaultTheme } from "./nav/themes";

type ProfileEntry = {
  icon: ReactElement;
  text: string;
  href: string;
  isActive: boolean;
};

export default function Profile() {
  const auth = useAuth();
  const workspace = useCurrentWorkspace();

  return (
    <div className="flex flex-col w-full justify-center gap-2">
      {(
        [
          {
            icon: <Icon name="settings" size={24} />,
            text: t("settings.title"),
            href: workspaces.edit.path(workspace),
            isActive: [
              workspaces.edit,
              otpApp.new,
              password.edit,
              security.show,
              members.index,
            ].some((x) => x.isCurrent()),
          },
          {
            icon: (
              <AvatarWithFallback
                avatarable={auth}
                size="sm"
                className="cursor-pointer"
              />
            ),
            text: auth.name,
            href: profile.edit.path(workspace),
            isActive: profile.edit.isCurrent(),
          },
        ] as ProfileEntry[]
      ).map((entry) => (
        <Link
          variant="ghost"
          className={cn(
            "gap-4 flex-grow items-center justify-start !px-4 !py-1",
            "hover:bg-inherit hover:text-primary",
            entry.isActive &&
              attrWithFallback(defaultTheme, [
                "tabs",
                "link",
                "activeClassName",
              ]),
          )}
          key={entry.text}
          href={entry.href}
        >
          {entry.icon}
          <div className="truncate">{entry.text}</div>
        </Link>
      ))}
    </div>
  );
}
